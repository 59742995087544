<template>
  <div>

    <div class="text-center">
      <img src="@/assets/images/login.svg" class="mb-3 login-img">
      <div class="row my-3" v-if="socialLogin">
        <div class="col-sm-6 pb-2">
          <a href="#" class="btn btn-white w-100 h-100">
            <div class="row align-items-center h-100">
              <div class="col-2">
                <img class="mr-2" src="@/assets/images/google.svg">
              </div>
              <div class="col-10 text-left">
                <span class="">
                  {{ $t('user.login.login_with', { service: 'Google' }) }}
                </span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-sm-6 pb-2">
          <a href="#" class="btn btn-white w-100 h-100">
            <div class="row align-items-center h-100">
              <div class="col-2">
                <img class="mr-2" src="@/assets/images/linkedin.svg">
              </div>
              <div class="col-10 text-left">
                <span class="">
                  {{ $t('user.login.login_with', { service: 'LinkedIn' }) }}
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="font-weight-bold mb-3" v-if="socialLogin">
        {{ $t('user.login.login_or') }}
      </div>
      <div class="font-weight-bold mb-3" v-if="!socialLogin">
        <el-card-title :title="$t('user.login.login_title')" />
      </div>
      <form @submit.prevent="login" class="form row justify-content-center">
        <div class="col-md-6 col-xxl-5">
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="email" v-model="email" type="email" class="form-control" :placeholder="$t('user.login.email')">
            <div class="form-control-feedback">
              <i class="icon-envelop3 text-muted"></i>
            </div>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input required autocomplete="current-password" v-model="password" type="password" class="form-control" :placeholder="$t('user.login.password')">
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted"></i>
            </div>
          </div>
        </div>
        <div class="mb-2 col-12">
          <button v-if="$store.state.auth.status != 'loading' && !isAuthenticated" type="submit" class="btn btn-success text-white btn-lg">{{ $t('user.login.login') }}</button>
          <div v-else class="bg-white p-2">
            <loader />
          </div>
        </div>
        <div class="my-2 col-12">
          <router-link class="font-weight-bold text-grey" tag="a" :to="{ path: '/login/password', query: { email }}">{{ $t('user.login.password_link') }}</router-link>
          <hr />
          <router-link class="font-weight-semibold text-secondary border-bottom" tag="a" to="/register">{{ $t('user.login.register_link') }}</router-link>
        </div>
      </form>
    </div>

  </div>
</template>

<script>

import { AUTH_REQUEST } from '../../store/mutations-types'
import qs from 'qs'

export default {
  data () {
    return {
      email: this.$route.query && this.$route.query.email ? this.$route.query.email : '',
      password: '',
      socialLogin: false,
      prevRoute: '/',
    }
  },
  beforeRouteEnter(to, from, next) {
      next(vm => {
          vm.prevRoute = from;
      });
  },
  mounted () {

  },
  methods: {
    login: function () {
      const { email, password } = this
      this.$store.dispatch('auth/' + AUTH_REQUEST, { email, password }).then(() => {
        let queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (queryStringFilters.redirect && queryStringFilters.redirect != '') {
          this.$router.push('/' + queryStringFilters.redirect)
          return
        } else {
          this.$router.push('/')
        }

      })
      .catch((error) => {
        this.notifError(error)
      })
    }
  },
  computed: {
    prevRoutePath() {return this.prevRoute ? this.prevRoute.path : '/'},
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
}
</script>

<style lang="scss" scoped>
  .login-img {
    max-height: 130px;
    max-width: 100%;
  }
  .btn-white {
    img {
      position: relative;
      top: -2px;
    }
  }
</style>
